
import React from 'react';
import { GiDesk,GiElevator,GiEscalator,GiPowerGenerator ,GiMeditation ,GiField,GiHighGrass ,GiLockers ,GiTheater,GiCctvCamera,GiVacuumCleaner,GiPoliceOfficerHead       } from "react-icons/gi";
import { FaSquareParking,FaBellConcierge,FaStore,FaRestroom,FaBus,FaNetworkWired      } from "react-icons/fa6";
import { CgGym } from "react-icons/cg";
import { TbSwimming } from "react-icons/tb";
import { SiClubhouse } from "react-icons/si";
import { MdOutlineDeck,MdCastForEducation ,MdLocalPharmacy ,MdOutlineRecycling,MdFoodBank  ,MdDryCleaning ,MdOutlineChildCare ,MdBusinessCenter ,MdOutlinePets,MdOutlineSecurity,MdLocalLaundryService,MdOutlineAspectRatio  ,MdAirlineSeatIndividualSuite,MdOutlineMeetingRoom,MdOutlineHvac      } from "react-icons/md";
import { FaChild,FaTrash,FaWifi,FaChargingStation ,FaShuttleVan   } from "react-icons/fa";
import { PiPersonSimpleBikeBold } from "react-icons/pi";
import { GrVmMaintenance,GrLounge  } from "react-icons/gr";
import { IoLibrary } from "react-icons/io5";
import { FcBusiness } from "react-icons/fc";
import { ImOffice } from "react-icons/im";
import { GrWheelchair,GrAtm,GrUserWorker  } from "react-icons/gr";
import { SiPhotocrowd } from "react-icons/si";
import { TbMassage } from "react-icons/tb";

import './Amenities.css';

const amenitiesData = [
  { name: "Lobby and Reception Area", icon: <GiDesk /> },
  { name: "Parking Facilities", icon: <FaSquareParking /> },
  { name: "Elevators", icon: <GiElevator /> },
  { name: "Escalators", icon: <GiEscalator /> },
  { name: "Fitness Center or Gym", icon: <CgGym /> },
  { name: "Swimming Pool", icon: <TbSwimming /> },
  { name: "Clubhouse or Community Room", icon: <SiClubhouse /> },
  { name: "Roof Deck or Terrace", icon: <MdOutlineDeck /> },
  { name: "Courtyards or Gardens", icon: <GiField /> },
  { name: "Children's Play Area", icon: <FaChild /> },
  { name: "Business Center", icon: <MdBusinessCenter /> },
  { name: "Pet-Friendly Amenities", icon: <MdOutlinePets /> },
  { name: "Security Systems", icon: <MdOutlineSecurity /> },
  { name: "Concierge Services", icon: <FaBellConcierge /> },
  { name: "Laundry Facilities", icon: <MdLocalLaundryService /> },
  { name: "Storage Units", icon: <FaStore /> },
  { name: "Bike Storage", icon: <PiPersonSimpleBikeBold /> },
  { name: "Trash Management", icon: <FaTrash /> },
  { name: "Maintenance Services", icon: <GrVmMaintenance /> },
  { name: "Wi-Fi Connectivity", icon: <FaWifi /> },
  { name: "Package Lockers", icon: <GiLockers /> },
  { name: "Guest Suites", icon: <MdAirlineSeatIndividualSuite /> },
  { name: "Library or Reading Room", icon: <IoLibrary /> },
  { name: "Movie Theater", icon: <GiTheater /> },
  { name: "Car Charging Stations", icon: <FaChargingStation /> },
  { name: "Common Areas", icon: <MdOutlineMeetingRoom  /> },
  { name: "Restrooms", icon: <FaRestroom  /> },
  { name: "CCTV Systems ", icon: <GiCctvCamera  /> },
  { name: "Fire Safety Systems ", icon: <GiCctvCamera  /> },
  { name: "HVAC Systems ", icon: <MdOutlineHvac  /> },
  { name: "Food and Beverage Options (Cafes, Restaurants, Food Courts)", icon: <MdFoodBank  /> },
  { name: "Conference and Meeting Rooms", icon: <MdOutlineMeetingRoom /> },
  { name: "Outdoor Spaces ", icon: <MdOutlineAspectRatio  /> },
  { name: "Green Spaces or Parks", icon: <GiHighGrass /> },
  { name: "Childcare Facilities or Daycare Center", icon: <MdOutlineChildCare  /> },
  { name: "Business Center Services ", icon: <FcBusiness /> },
  { name: "On-Site Management Office", icon: <ImOffice /> },
  { name: "Janitorial and Cleaning Services", icon: <GiVacuumCleaner  /> },
  { name: "Recycling and Waste Management Facilities", icon: <MdOutlineRecycling  /> },
  { name: "Emergency Power Backup Systems", icon: <GiPowerGenerator  /> },
  { name: "Disability Access (Ramps, Elevators, Restroom Facilities)", icon: <GrWheelchair /> },
  { name: "Public Transportation Access (Nearby Bus Stops, Train Stations)", icon: <FaBus  /> },
  { name: "Shuttle or Transportation Services", icon: <FaShuttleVan /> },
  { name: "ATM or Banking Services", icon: <GrAtm /> },
  { name: "Dry Cleaning Pickup and Delivery", icon: <MdDryCleaning  /> },
  { name: "Pharmacy or Medical Clinic", icon: <MdLocalPharmacy  /> },
  { name: "Pet-Friendly Facilities", icon: <MdOutlinePets /> },
  { name: "Art Installations or Exhibits", icon: <SiPhotocrowd /> },
  { name: "Educational Programs or Workshops", icon: <MdCastForEducation  /> },
  { name: "Lounge Areas with Wi-Fi Access", icon: <GrLounge  /> },
  { name: "Security Patrols", icon: <GiPoliceOfficerHead  /> },
  { name: "On-Site Maintenance Staff", icon: <GrUserWorker  /> },
  { name: "Massage Therapy or Wellness Center", icon: <TbMassage /> },
  { name: "Meditation or Relaxation Rooms", icon: <GiMeditation  /> },
  { name: "Tenant Events or Networking Opportunities", icon: <FaNetworkWired  /> }
];



const AmenitySection = ({ confirmedAmenities }) => {
  return (
    <div className="amenity-section">
      <div className="amenities">

{confirmedAmenities.map((amenity, index) => {
  const matchedAmenity = amenitiesData.find(item => {
    return item.name.toLowerCase().includes(amenity.toLowerCase()) || amenity.toLowerCase().includes(item.name.toLowerCase());
  });
  if (matchedAmenity) {
    return (
      <div key={index} className="amenity">
        <div className="amenityIcon">
          {matchedAmenity.icon}
        </div>
        <div className="amenityName">
          <span>{matchedAmenity.name}</span>
        </div>
      </div>
    );
  }
  return null;
})}


      </div>
    </div>
  );
};

export default AmenitySection;
