
import React, { useState, useEffect } from "react";
import "./FocusedProjects.css";
import Newicon from "Assests/icon.png";
import { useNavigate } from "react-router-dom";
import useRecentlyAddedData from "DataBase/PropertyData/PropertyData";
import toast from 'react-hot-toast';
import app from "DataBase/FireBaseConfi";
import { getAuth } from "firebase/auth";
import { Skeleton } from 'primereact/skeleton';

function FocusedProjects() {
  const navigate = useNavigate();
  const FocusedProject = useRecentlyAddedData();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const focusedProjectsToAdd = FocusedProject.filter((project) => {
    let isMatching = true;

    if (project.AddToFocus !== "Yes") {
      isMatching = false;
    }

    return isMatching;
  });

  function handleFocusedCardClick(Focused) {
 

    navigate(`/PropertyDetails/${Focused.propertyname}`, {
      state: { Data: Focused },
    });
  }

  return (
    <div className="FocusProjectMainContainer">
      <div className="wrapper-grey padded">
    
            <h2 className="RecentlyAddedH2">
              Projects in <b>Focus</b>
            </h2>
          </div>
          <div className="FocusedCardsparentDiv">
            {focusedProjectsToAdd.map((Focused) => (
              <div
                className="FocusedCardsMainDiv"
                key={Focused.id}
                onClick={() => handleFocusedCardClick(Focused)}
              >
                <div
                  className="focusedcards"
              
                >
                  <div className="focusedcardsImg">
                    <img src={Focused.coverImageUrl} alt="" />
                  </div>

                  <div className="focusedcards-category">
                    <h6>{Focused.propertyLocality}</h6>
                    <h6>{Focused.propertyLocation}</h6>
                  </div>
                  <div className="focusedcards-description">
                    <div>
                      <h2>{Focused.propertyname}</h2>
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {Focused.subtype}
                      </p>
                    </div>
                    <div className="FPrice">
                      <h6>&#8377; {Focused.propertyPrice}</h6>
                    </div>
                  </div>
                  <img
                    className="focusedcards-user avatar avatar-small"
                    src={Newicon}
                    alt="User Avatar"
                  />
                </div>
              </div>
            ))}
            {focusedProjectsToAdd.length === 0 && (
              <div className="w-full " style={{display:'flex', justifyContent:'center'}}>
                <div className="flex align-items-end">
                  <Skeleton size="20rem"></Skeleton>
                </div>
              </div>
            )}
          </div>
        </div>
     
   
  );
}

export default FocusedProjects;
