import React from "react";
import "./Testimonials.css";

function Testimonials() {
  const testimonialsData = [
    {
      imageSrc: "https://images.unsplash.com/photo-1599505043552-fa95de5548ac?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      caption: "Neha Kumari",
      text: "I'm thrilled with my new home in Delhi! The team at Ncr-RealEstate made the process so smooth and enjoyable.",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1621331938577-42f137e5d5f1?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      caption: "Aditya Raj",
      text: "Finding my dream apartment in Noida was a breeze with Ncr-RealEstate. Highly recommend their services!",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1558203728-00f45181dd84?q=80&w=1774&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      caption: "Bhanu Singh",
      text: "Gurgaon is where I found my perfect home, thanks to Ncr-RealEstate. Professional and friendly service throughout.",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      caption: "Vishwajeet Raj",
      text: "I never thought finding my dream home could be this easy. Thanks to Ncr-RealEstate for their excellent service and guidance throughout the process.",
    },
    
    
    // Add more testimonial objects as needed
  ];

  const numSlides = testimonialsData.length;

  return (
    <div className="Testimonials">
    
      <div className="slider">
        {testimonialsData.map((_, index) => (
          <input
            key={index}
            type="radio"
            name="slider"
            title={`slide${index + 1}`}
            className="slider__nav"
            style={{ "--slides": numSlides }}
          />
        ))}
        <div className="slider__inner">
          {testimonialsData.map((testimonial, index) => (
            <div key={index} className="slider__contents">
                <div className="slider__contentsImgContainer">
                   <img
                className="slider__image"
                src={testimonial.imageSrc}
                alt=""
              />  
                </div>
             
              <h2 className="slider__caption">{testimonial.caption}</h2>
              <p className="slider__txt">{testimonial.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
