
import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MyNavbar from "Components/NavBar/NavBar";
import LandingPage from "Components/LadingPage/LandingPage";
import About from "Components/About/About";
import Contact from "Components/Contact/Contact";
import PropertyDetails from "Components/PropertyDetails/PropertyDetails";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import CategoriesDetails from "Components/CategoriesDetails/CategoriesDetails";
import Header from "Components/PhoneVersion/Header/Header";
import PhLandingPage from "Components/PhoneVersion/PhLandingPage/PhLandingPage";
import PhPropertyDetails from "Components/PhoneVersion/PhPropertyDetails/PhPropertyDetails";
import PhCategoryDetails from "Components/PhoneVersion/PhCategoryDetails/PhCategoryDetails";

import Footer from "Components/Footer/Footer";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 420);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 420);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      {isMobile ? (
        <div>
          <Header />
          
          <Routes>
            <Route path="/" element={<Navigate to="home" replace />} />
            <Route path="/home" element={<PhLandingPage />} />
            <Route path="/PropertyDetails/:projectId" element={<PhPropertyDetails />} />
            <Route path="/Category_Data" element={<PhCategoryDetails />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
          </Routes>
    
          <Footer/>
          {/* <PhPropertyDetails/> */}
        </div>
      ) : (
        <div>  
         
          <MyNavbar />
          <Routes>
            <Route path="/" element={<Navigate to="home" replace />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
           
            
            <Route path="/PropertyDetails/:projectId" element={<PhPropertyDetails />} />
            <Route path="/Category_Data" element={<CategoriesDetails />} />
          </Routes>
          <Footer/>
        </div>
      )}
    </Router>
  );
};

export default App;
