import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className='footertext'>
     Copyright &#169;  2024 <b>NCR-RealEstate</b>. All rights reserved. <br />
This material may not be reproduced, displayed, modified, or distributed without the express prior written permission of <b>NCR-RealEstate.</b>

    </div>
  )
}

export default Footer
