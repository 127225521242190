
import React from "react";
import "./ProjectLocation.css";
import { FaSchool, FaHospital, FaTrainSubway, FaBus,FaTrainTram  } from "react-icons/fa6";
import { CgGym } from "react-icons/cg";
import { BsBank } from "react-icons/bs";
import { PiParkFill } from "react-icons/pi";
import { GiCommercialAirplane } from "react-icons/gi";
import { MdLocalMall } from "react-icons/md";

function ProjectLocation({ ProjectLocationDetails }) {
  const { NearbyLocation, EmbededMap } = ProjectLocationDetails || {};

  const getIconForLocation = (name) => {
    const lowercaseName = name.toLowerCase();

    if (lowercaseName.includes("school")) {
      return <FaSchool />;
    } else if (lowercaseName.includes("hospital")) {
      return <FaHospital />;
    } else if (lowercaseName.includes("gym")) {
      return <CgGym />;
    } else if (lowercaseName.includes("metro")) {
      return <FaTrainSubway />;
    } else if (lowercaseName.includes("train")) {
      return <FaTrainTram />;
    } else if (lowercaseName.includes("bank")) {
      return <BsBank />;
    } else if (lowercaseName.includes("park")) {
      return <PiParkFill />;
    } else if (lowercaseName.includes("mall")) {
      return <MdLocalMall />;
    } else if (lowercaseName.includes("airport")) {
      return <GiCommercialAirplane />;
    }

    return <FaBus />;
  };

  return (
    <div className="ProjectLocationMainDiv">
      <div className="ProjectLocationMap">
        <iframe
          src={EmbededMap}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="AroundPropertyMainDiv">
        {NearbyLocation &&
          NearbyLocation.map((location, index) => (
            <div className="AroundProperty flex gap-3" key={index}>
              <div style={{ fontSize: "45px" }}>
                {getIconForLocation(location.name)}
              </div>
              <div>
                <h6>{location.name}</h6>
                <p style={{ color: "gray" }}>{location.distance} </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ProjectLocation;
