import React from "react";
import "./PhHeaderContent.css";
import Search from "Components/Search/Search";
import PhSearch from "../PhSearch/PhSearch";
function PhHeaderContent() {
  return (
    <div className="PhHeaderContentMainDiv">
      <div className="PhHeaderContentBGBLUR">
        <div className="PhHeaderContent">
          <div class="Phtext">
            <span>Discover</span> <br />
            <span>Most Suitable</span>
            <br />
            <span>Property</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhHeaderContent;
