
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Nav.css'; 
import Logo from 'Components/Logo/Logo';
import LoginDropdown from '../Login/LoginDropdown';
import { ToastContainer } from 'react-toastify';

const MyNavbar = () => {
  const location = useLocation();

  return (
    <Navbar className="custom-navbar" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Logo />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" className={`nav-link ${location.pathname === '/home' ? 'active' : ''}`}>Home</Nav.Link>
            {/* <Nav.Link as={Link} to="/gallery" className={`nav-link ${location.pathname === '/gallery' ? 'active' : ''}`}>Gallery</Nav.Link> */}
            <Nav.Link as={Link} to="/about" className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`}>About</Nav.Link>
            <Nav.Link as={Link} to="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</Nav.Link>
          </Nav>

          <Nav>
            {/* <Nav.Link as={Link} to="/login" className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}>Login</Nav.Link> */}
            <LoginDropdown/>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <ToastContainer/>
    </Navbar>
  );
};

export default MyNavbar;
