import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./PhNav.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";

function PhNav() {
  const [activeLink, setActiveLink] = useState("home"); 
  const handleSetActiveLink = (link) => {
    setActiveLink(link);
    document.getElementById("dropdown").checked = false;
  };

  return (
    <div>
      <div className="sec-center">
        <input
          className="dropdown"
          type="checkbox"
          id="dropdown"
          name="dropdown"
        />
        <label className="for-dropdown" htmlFor="dropdown">
          <GiHamburgerMenu />
        </label>
        <div className="section-dropdown">
          <Link
            to="/"
            className={`nav-link ${activeLink === "home" ? "active" : ""}`}
            onClick={() => handleSetActiveLink("home")}
          >
            <i className="fa fa-home" aria-hidden="true"></i> Home
          </Link>

          <Link
            to="/About"
            className={`nav-link ${activeLink === "About" ? "active" : ""}`}
            onClick={() => handleSetActiveLink("About")}
          >
            <i className="fa fa-info-circle" aria-hidden="true"></i> About Us
          </Link>

          <Link
            to="/Contact"
            className={`nav-link ${
              activeLink === "Contact" ? "active" : ""
            }`}
            onClick={() => handleSetActiveLink("Contact")}
          >
            <i className="fa fa-comment" aria-hidden="true"></i> Contact Us
          </Link>
        </div>
      </div>
      <Toaster/>
      <ToastContainer/>
    </div>
  );
}

export default PhNav;
