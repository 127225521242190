import React, { useState } from "react";
import "./PhCategoryDetails.css";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { MdFilterListAlt } from "react-icons/md";

function CategoriesDetails() {
  const location = useLocation();
  const { Data, Title } = location.state || {};
  const navigate = useNavigate();
  const [showFilter, setShowfilter] = useState(false);
  const [sortByBudget, setSortByBudget] = useState("0");
  const [propertyType, setPropertyType] = useState("0");
  const [sortByLocation, setSortByLocation] = useState("0");
  const [sortBySubType, setSortBySubType] = useState("0");

  const handlePhCategoryDataClick = (index) => {
    navigate(`/PropertyDetails/${index.propertyname}`, {
      state: { Data: index },
    });
  };

  const handleSortByBudgetChange = (event) => {
    setSortByBudget(event.target.value);
  };

  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleSortByLocationChange = (event) => {
    setSortByLocation(event.target.value);
  };

  const handleSortBySubTypeChange = (event) => {
    setSortBySubType(event.target.value);
  };

  const filteredData = Data.filter((property) => {
    let isMatching = true;

    // Apply filtering based on property type
    if (
      propertyType &&
      propertyType !== "0" &&
      property.propertyType !== propertyType
    ) {
      isMatching = false;
    }

    if (
      sortBySubType &&
      sortBySubType !== "0" &&
      property.subtype !== sortBySubType
    ) {
      isMatching = false;
    }

    // Apply filtering based on budget
    if (sortByBudget && sortByBudget !== "0") {
      const budgetValue = parseFloat(sortByBudget);
      let propertyPriceValue = parseFloat(property.propertyPrice);

      if (isNaN(propertyPriceValue) || propertyPriceValue > budgetValue) {
        isMatching = false;
      }
    }

    // Apply filtering based on location
    if (sortByLocation && sortByLocation !== "0") {
      const locationParts = sortByLocation.toLowerCase().split(" ");
      const propertyLocationLower = property.propertyLocation.toLowerCase();
      const locationMatched = locationParts.some((part) =>
        propertyLocationLower.includes(part)
      );
      if (!locationMatched) {
        isMatching = false;
      }
    }

    return isMatching;
  });

  const handleResetClick = () => {
    setSortByBudget("0");
    setPropertyType("0");
    setSortByLocation("0");
    setSortBySubType("0");
  };
  return (
    <div>
      <h2 className="PhCategoryTitle">
        <b>{Title}</b>
      </h2>
      <div className="PhFilterMainDiv">
        {!showFilter && (
          <MdFilterListAlt
            onClick={() => setShowfilter(true)}
            className="FilterIcon"
          />
        )}
        {showFilter && (
          <MdFilterListAlt
            onClick={() => setShowfilter(false)}
            className="FilterIcon"
          />
        )}
        {showFilter && (
          <div className="PhCategoriesDetailsBudgetParentDiv">
            <div className="PhCategoriesDetailsBudget">
              <h6>&#x20b9; Budget:</h6>
              <select
                className="PhCategorySelect"
                onChange={handleSortByBudgetChange}
                value={sortByBudget}
              >
                <option value="0">Sort By Budget </option>
                <option value="1">Under 1 Cr </option>
                <option value="4">Under 4 Cr</option>
                <option value="6">Under 6 Cr</option>
                <option value="8">Under 8 Cr</option>
                <option value="10">Under 10 Cr</option>
                <option value="12">Under 12 Cr</option>
                <option value="14">Under 14 Cr</option>
                <option value="16">Under 16 Cr</option>
                <option value="20">Under 20 Cr</option>
                <option value="50">Under 50 Cr</option>
              </select>
            </div>

            <div className="PhCategoriesDetailsBudget">
              <h6> Property Type:</h6>
              <select
                className="PhCategorySelect"
                onChange={handlePropertyTypeChange}
                value={propertyType}
              >
                <option value="0">Sort By Property Type</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
              </select>
            </div>

            <div className="PhCategoriesDetailsBudget">
              <h6> Property Location:</h6>
              <select
                className="PhCategorySelect"
                onChange={handleSortByLocationChange}
                value={sortByLocation}
              >
                <option value="0">Sort By Location </option>
                <option value="Delhi">Delhi </option>
                <option value="Mumbai">Mumbai</option>
                <option value="Gurugram">Gurugram</option>
                <option value="Noida">Noida</option>
              </select>
            </div>
            <div className="PhCategoriesDetailsBudget">
              <h6> Property Sub-Type:</h6>
              <select
                className="PhCategorySelect"
                onChange={handleSortBySubTypeChange}
                value={sortBySubType}
              >
                <optgroup label="Residential" className="Optgroup">
                  <option value="0">Select Sub Type</option>
                  <option value="Apartments">Apartments</option>
                  <option value="Floors">Floors</option>
                  <option value="Plots">Plots</option>
                  <option value="Villas">Villas</option>
                  <option value="Tailor-Made Apartments">
                    Tailor-Made Apartments
                  </option>
                  <option value="Independent Floors">Independent Floors</option>
                  <option value="Triplex Sky villa">Triplex Sky villa</option>
                  <option value="Duplex Sky villa">Duplex Sky villa</option>
                  <option value="Triplex Duplex Sky villa">
                    Triplex &amp; Duplex Sky villa
                  </option>
                  <option value="Simplex Duplex">Simplex &amp; Duplex</option>
                </optgroup>
                <optgroup label="Commercial" className="Optgroup">
                  <option value="0">Select Sub Type</option>
                  <option value="Retails">Retails</option>
                  <option value="Offices">Offices</option>
                  <option value="Serviced Apartments">
                    Serviced Apartments
                  </option>
                  <option value="Medical Suites">Medical Suites </option>
                  <option value="IT / ITES">IT / ITES </option>
                  <option value="Hotel Apartments">Hotel Apartments </option>
                  <option value="Studio Apartments">Studio Apartments</option>
                </optgroup>
              </select>
            </div>

            <div className="CategoriesDetailsFilterBtn">
              <Button
                label="Reset"
                style={{
                  backgroundColor: "lightblue",
                  padding: "8px",
                  borderRadius: "10px",
                }}
                onClick={handleResetClick}
              />
            </div>
          </div>
        )}
      </div>

      <div
        className=" CategoriesDetailsCards p-3"
        style={{ textAlign: "left", cursor: "pointer" }}
      >
        {filteredData.map((index) => (
          <div
            className="row CategoriesDetailsCardContent"
            key={index}
            onClick={() => handlePhCategoryDataClick(index)}
          >
            {/* Cover Image Here */}
            <div className="col-md-4">
              <div className="CategoriesDetailsImg">
                <img src={index.coverImageUrl} alt="PhCategory" />
              </div>
            </div>
            {/* Card Content Here */}
            <div className="col-md-8">
              <div className="CategoriesDetailsContent">
                <div className="PhCategoryFirstRow">
                  <div className="PhCategoryFirstRowFirstCol">
                    <h3>{index.propertyname}</h3>
                    <p>
                      <i className="bx bxs-map"></i>
                      {index.propertyLocality + " - " + index.propertyLocation}
                    </p>
                    <p>
                      <b>By : </b>
                      {index.propertyBy}
                    </p>
                  </div>
                  <div className="PhCategoryFirstRowSecCol">
                    <p>
                      <i className="bx bx-rupee"></i> {index.propertyPrice}
                    </p>
                  </div>
                </div>
                <div className=" PhCategoryMidRow">
                  <div>
                    <p>
                      {" "}
                      <b>Property Type</b>
                    </p>
                    <p>{index.propertyType}</p>
                  </div>
                  <div>
                    <p>
                      <b>Sub Type</b>
                    </p>
                    <p>{index.subtype}</p>
                  </div>
                  <div>
                    <p>
                      <b>Availability</b>
                    </p>
                    <p>Booking Open</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))} 
        
        
       
      </div>

      <div className="PhContactBtn">
        <a href="tel:9205940363">
           <button className="btn">
          <i class="fa fa-phone-square" aria-hidden="true"></i>
          Contact Us
        </button>
        </a>
       
      </div>
    </div>
  );
}

export default CategoriesDetails;
