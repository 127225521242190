import React, { useState, useEffect } from "react";
import "./PhPropertyDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import PhOverview from "./PhOverview/PhOverview";
import AmenitySection from "Components/PropertyDetails/Amenities/Amenities";
import ProjectLocation from "Components/PropertyDetails/ProjectLocation/ProjectLocation";
import PriceList from "Components/PropertyDetails/PriceList/PriceList";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
function PhPropertyDetails() {
  const [activeTab, setActiveTab] = useState("Overview");
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedImage, setSelectedImage] = useState("");
  useEffect(() => {
    if (!location.state || !location.state.Data) {
      navigate("/");
    }
  }, [location, navigate]);
  const { Data } = location.state || {};

  const propertyType = Data?.propertyType;
  const isResidential = propertyType === "Residential";
  const ResidentialTabs = [
    "Overview",
    "Amenities",
    "Location",
    "Living Area",
    "Bedroom",
    "Kitchen",
    "Floor Plans",
    "More Images",
    "Price List",
  ];
  const CommercialTabs = [
    "Overview",
    "Amenities",
    "Location",
    "Floor Plans",
    "Other Images",
    "Price List",
  ];

  const shuffledTabs = isResidential ? ResidentialTabs : CommercialTabs;

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };
  return (
 <div>
     <div className="PhPropertyDetailsMainParentDiv">
      <div className="PhPropertyHeaderContent">
          <div className="PhPropertyCoverImg">
          <img
            src={
              Data.coverImageUrl ||
              "https://th.bing.com/th/id/R.f20d69e5e6232ae5719663b216cc1b25?rik=PhZaT8%2bRepLBCw&riu=http%3a%2f%2fupload.wikimedia.org%2fwikipedia%2fcommons%2f0%2f03%2fGalveston_Federal_Building_2009.jpg&ehk=07SvDu9p9luMPMqkSnFU1pU4jkYmy8mDNICAQSI8L6A%3d&risl=1&pid=ImgRaw&r=0"
            }
            alt="Cover"
          />
        </div>
        <div className="PhPropertyContentFirstRow">
            <div className="PhPropertyName">
              <h4>{Data.propertyname || "No Data"}</h4>
              <p>
                <b>By : </b> {Data.propertyBy || "No Data"}
              </p>
              <p>
                {" "}
                {Data.propertyLocality + "-" + Data.propertyLocation ||
                  "No Data"}{" "}
              </p>
              <p>Last Updated : {Data.Date}</p>
            </div>
            <div className="PhPropertyLogo">
              <img
                src={
                  Data.BuilderIconImageUrl ||
                  "https://cdn3.iconfinder.com/data/icons/abstract-1/512/no_image-512.png"
                }
                alt="Builder logo"
              />
            </div>
          </div>
        
      </div>
      
        <div className="PhPropertyContent">
        <div className="PhPropertyContentSecondRow ">
            <div className="PhPropertyPrice">
              <label style={{ fontSize: "13px", fontWeight: "600" }}>
                Price :
              </label>
              <b>&#x20b9; </b> { Data.propertyPrice || "No Data"}
            </div>
            <div className="PhPropertySize">
              <label style={{ fontSize: "13px", fontWeight: "600" }}>
                Area :
              </label>

              {Data.sitearea || "No Data"}
            </div>
            <div className="PhPropertyStatus">
              <label style={{ fontSize: "13px", fontWeight: "600" }}>
                Status :
              </label>

              {Data.propertyStatus || "No Data"}
            </div>
            <div className="PhPropertySubType">
              <label style={{ fontSize: "13px", fontWeight: "600" }}>
                SubType :
              </label>

              {Data.subtype || "No Data"}
            </div>
          </div>
          <div className="PhPropertyDetailsMainDiv">
            <div className="PhPropertyContentThirdRow">
              <div className="PhPropertyTabs">
                {shuffledTabs.map((tab) => (
                  <button
                    key={tab}
                    className={activeTab === tab ? "Phtab active" : "Phtab"}
                    onClick={() => handleTabClick(tab)}
                  >
                    <p>{tab}</p>
                  </button>
                ))}
              </div>
              <div className="PhPropertyTabsContent">
                {/* Overview */}
                <div
                  id="Overview"
                  className={
                    activeTab === "Overview"
                      ? "PhtabContent active"
                      : "PhtabContent"
                  }
                >
                  <div className="PhOverviewTabMainContainer">
                    <PhOverview
                      PhOverViewDetails={{
                        propertyname: Data.propertyname,
                        propertyType: Data.propertyType,
                        subtype: Data.subtype,
                        sitearea: Data.sitearea,
                        ComplitionYear: Data.ComplitionYear,
                        rera: Data.rera,
                        description: Data.description,
                        status: Data.propertyStatus,
                      }}
                    />
                  </div>
                </div>

                {/* Amenities */}
                <div
                  id="Amenities"
                  className={
                    activeTab === "Amenities"
                      ? "PhtabContent active"
                      : "PhtabContent"
                  }
                >
                  <div>
                    <AmenitySection
                      confirmedAmenities={Data.confirmedAmenities}
                    />
                  </div>
                </div>

                {/* Location */}
                <div
                  id="Location"
                  className={
                    activeTab === "Location"
                      ? "PhtabContent active"
                      : "PhtabContent"
                  }
                >
                  <div>
                    <ProjectLocation
                      ProjectLocationDetails={{
                        EmbededMap: Data.EmbededMap,
                        NearbyLocation: Data.locations,
                      }}
                    />
                  </div>
                </div>

                {/* Floor Plans */}
                <div
                  id="Floor Plans"
                  className={
                    activeTab === "Floor Plans"
                      ? "PhtabContent active "
                      : "PhtabContent"
                  }
                >
                  <div className="PhPropertyImages">
                    {Data.FloorPlanUrls.map((image, index) => (
                      <div
                        key={index}
                        className="PhPropertyImagesDiv"
                        onClick={() => handleImageClick(image)}
                      >
                        <img src={image} alt={`Floor Plan ${index + 1}`} />
                      </div>
                    ))}
                  </div>
                </div>

                {/* More Images */}
                {isResidential && (
                  <div
                    id="More Images"
                    className={
                      activeTab === "More Images"
                        ? "PhtabContent active"
                        : "PhtabContent"
                    }
                  >
                    <div className="PhPropertyImages">
                      {Data.ExtraImageUrl.map((image, index) => (
                        <div
                          key={index}
                          className="PhPropertyImagesDiv"
                          onClick={() => handleImageClick(image)}
                        >
                          <img src={image} alt={`Other Images ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Other Images */}
                {!isResidential && (
                  <div
                    id="Other Images"
                    className={
                      activeTab === "Other Images"
                        ? "PhtabContent active"
                        : "PhtabContent"
                    }
                  >
                    <div className="PhPropertyImages">
                      {Data.otherImageUrls.map((image, index) => (
                        <div
                          key={index}
                          className="PhPropertyImagesDiv"
                          onClick={() => handleImageClick(image)}
                        >
                          <img src={image} alt={`Other Images ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Price List */}
                <div
                  id="Price List"
                  className={
                    activeTab === "Price List"
                      ? "PhtabContent active"
                      : "PhtabContent"
                  }
                >
                  <div>
                    <PriceList priceDetails={Data.priceDetails} />
                  </div>
                </div>

                {/* Living Area */}
                {isResidential && (
                  <div
                    id="Living Area"
                    className={
                      activeTab === "Living Area"
                        ? "PhtabContent active"
                        : "PhtabContent"
                    }
                  >
                    <div className="PhPropertyImages">
                      {Data.LivingAreaUrl.map((image, index) => (
                        <div
                          key={index}
                          className="PhPropertyImagesDiv"
                          onClick={() => handleImageClick(image)}
                        >
                          <img src={image} alt={`Living Area ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Bedroom */}
                {isResidential && (
                  <div
                    id="Bedroom"
                    className={
                      activeTab === "Bedroom"
                        ? "PhtabContent active"
                        : "PhtabContent"
                    }
                  >
                    <div className="PhPropertyImages">
                      {Data.BedroomImgUrl.map((image, index) => (
                        <div
                          key={index}
                          className="PhPropertyImagesDiv"
                          onClick={() => handleImageClick(image)}
                        >
                          <img src={image} alt={`Bedroom ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* Kitchen */}
                {isResidential && (
                  <div
                    id="Kitchen"
                    className={
                      activeTab === "Kitchen"
                        ? "PhtabContent active"
                        : "PhtabContent"
                    }
                  >
                    <div className="PhPropertyImages">
                      {Data.KitchenImages.map((image, index) => (
                        <div
                          key={index}
                          className="PhPropertyImagesDiv"
                          onClick={() => handleImageClick(image)}
                        >
                          <img src={image} alt={`Kitchen ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="PhImgModelMainDiv">
                  <Dialog
                    visible={showModal}
                    modal
                    onHide={() => setShowModal(false)}
                    content={({ hide }) => (
                      <div className="PhImgModel p-3">
                        <Button
                          label="x"
                          onClick={(e) => hide(e)}
                          text
                        ></Button>

                        <img src={selectedImage} alt="Selected" />
                        <div className="flex align-items-center gap-2"></div>
                      </div>
                    )}
                  ></Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="PhContactBtn">
        <a href="tel:9205940363">
           <button className="btn">
           <i class='bx bxs-phone-call'></i>
          Contact Us
        </button>
        </a>
       
      </div>
 </div>
   
    
  );
}

export default PhPropertyDetails;
