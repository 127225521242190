import React from "react";
import "./PhOverview.css";

function PhOverview(data) {
  const {
    propertyname,
    propertyType,
    subtype,
    sitearea,
    ComplitionYear,
    rera,
    description,
    status,
  } = data.PhOverViewDetails || {};

  const isResidential = propertyType === "Residential";
  return (
    <div>
      <div className="OverviewMainDiv">
        <header className="OverviewHeader">
          <div className="text-effect ">
            <span >{propertyname || "No Data"}</span>
          </div>
        </header>
        <div className="OverviewPropertyDetails">
          <div>
            <p>
              <b>
                <i className="bx bx-building-house"></i> Property Type
              </b>
            </p>
            <p>{propertyType || "No Data"}</p>
          </div>
          <div>
            <p>
              <b>Sub Type</b>
            </p>
            <p>{subtype || "No Data"}</p>
          </div>
          <div>
            <p>
              <b>
                <i className="bx bx-area"></i>Site Area
              </b>
            </p>
            <p>{sitearea || "No Data"}</p>
          </div>
         
          <div>


            <div id="ReraDiv">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="25px "
                viewBox="0 0 561.000000 540.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,540.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2585 4704 c-381 -45 -689 -172 -980 -407 -172 -139 -366 -362 -472
-544 l-48 -81 -270 -4 c-295 -3 -315 -7 -345 -64 -21 -38 -17 -109 7 -142 40
-54 -15 -52 1515 -50 l1420 3 34 37 c29 32 34 45 34 87 0 51 -21 90 -63 114
-17 9 -254 13 -1040 17 l-1017 5 79 105 c181 241 426 442 676 552 419 186 913
177 1330 -22 253 -121 489 -323 650 -555 l56 -80 -145 -6 c-161 -6 -187 -15
-212 -74 -25 -61 -13 -116 36 -160 21 -19 38 -20 536 -23 340 -2 528 1 554 8
51 14 82 56 82 116 1 55 -14 85 -55 110 -29 17 -58 20 -276 24 l-244 5 -54 90
c-353 586 -981 951 -1626 944 -73 -1 -145 -4 -162 -5z"
                  />
                  <path
                    d="M730 2680 l0 -490 100 0 100 0 0 150 0 150 78 -1 77 0 95 -149 95
-149 129 0 c101 -1 126 2 119 12 -24 29 -213 327 -213 334 0 5 19 17 42 27 58
27 124 96 137 142 16 56 13 192 -4 251 -21 72 -74 136 -137 167 -70 34 -182
46 -425 46 l-193 0 0 -490z m498 299 c43 -15 72 -65 72 -123 0 -51 -10 -78
-43 -113 -20 -22 -28 -23 -174 -23 l-153 0 0 135 0 135 134 0 c73 0 147 -5
164 -11z"
                  />
                  <path
                    d="M1710 2680 l0 -490 345 0 345 0 0 100 0 100 -245 0 -245 0 0 100 0
100 210 0 210 0 0 90 0 90 -210 0 -210 0 0 100 0 100 245 0 245 0 0 100 0 100
-345 0 -345 0 0 -490z"
                  />
                  <path
                    d="M2587 3163 c-4 -3 -7 -224 -7 -490 l0 -483 100 0 100 0 0 150 0 150
78 0 77 0 97 -150 97 -150 121 0 c66 0 120 2 120 5 0 2 -47 79 -105 170 -58
91 -105 169 -105 174 0 4 15 14 33 23 17 9 51 30 74 47 36 26 45 41 64 102 18
58 21 88 17 158 -11 181 -99 269 -293 291 -100 11 -458 14 -468 3z m507 -212
c51 -42 71 -144 40 -202 -24 -48 -65 -59 -219 -59 l-135 0 0 141 0 141 148 -4
c111 -2 153 -7 166 -17z"
                  />
                  <path
                    d="M3845 3133 c-126 -300 -385 -931 -385 -936 0 -4 44 -7 98 -7 l98 0
35 108 35 107 201 3 201 2 37 -110 37 -110 114 0 114 0 -10 25 c-36 95 -385
933 -393 943 -6 8 -37 12 -88 12 l-79 0 -15 -37z m174 -380 c27 -70 53 -136
56 -145 7 -17 -3 -18 -120 -18 -70 0 -125 4 -123 9 68 179 124 307 130 296 5
-7 30 -71 57 -142z"
                  />
                  <path
                    d="M528 1961 c-43 -9 -64 -24 -84 -62 -20 -39 -17 -88 6 -127 35 -58 60
-62 348 -62 l260 0 46 -83 c128 -228 370 -481 604 -631 381 -244 814 -347
1224 -291 268 37 554 141 771 281 253 164 496 424 622 666 l30 58 233 0 c260
0 287 5 322 62 34 56 21 155 -24 181 -16 9 -491 12 -2171 13 -1182 1 -2167 -1
-2187 -5z m3592 -258 c0 -16 -146 -199 -212 -265 -467 -471 -1154 -623 -1750
-388 -119 47 -283 138 -383 213 -135 100 -318 292 -400 420 l-17 27 1381 0
c759 0 1381 -3 1381 -7z"
                  />
                </g>
              </svg>
              <p>
                <b>Rera</b>
              </p>
            </div>
            <p>{rera || "No Data"}</p>
          </div>
          <div>
            <p>
              <b>
                <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                Completion Year
              </b>
            </p>
            <p>{ComplitionYear || "No Data"}</p>
          </div>
          <div>
            <p>
              <b>
                <i className="fa fa-industry" aria-hidden="true"></i>
                Property Status
              </b>
            </p>
            <p>{status || "No Data"}</p>
          </div>
        </div>

        <main className="OverviewPropertyMainContent">
          <h6>{description || "No Data"}</h6>
        </main>
      </div>
    </div>
  );
}

export default PhOverview;
