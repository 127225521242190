// redux/store.js

import { configureStore } from '@reduxjs/toolkit';
import filterReducer from './filterSlice'; // assuming you'll have a reducer named filterSlice
import userReducer from "./userSlice";

const store = configureStore({
  reducer: {
    user: userReducer, 
    filter: filterReducer // Add other reducers here if needed
  }
});

export default store;
