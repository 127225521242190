
import React, { useState, useEffect } from 'react';
import './Numbers.css';

function Numbers() {
  const [counters, setCounters] = useState([0, 0, 0]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment counters by 1 until they reach their final value
      setCounters(prevCounters =>
        prevCounters.map((counter, index) => {
          const finalValue = [100, 250, 15][index]; // Replace with your final values
          return counter < finalValue ? counter + 3 : finalValue;
        })
      );
    }, 100); // Adjust interval as needed

    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className="Numbermaindiv">
        <div className="row ">
          <div className="col-md-3">
            <h4 className='mainnumber'>{counters[0]}
            <span style={{color:'orange'}}> +</span></h4>
            <p className='Numberspara'>Premium Properties</p>
          </div>
          <div className="col-md-3">
            <h4 className='mainnumber'>{counters[1]}<span style={{color:'orange'}}> +</span></h4>
            <p className='Numberspara'>Happy Customers</p>
          </div>
          <div className="col-md-3">
            <h4 className='mainnumber'>{counters[2]}<span style={{color:'orange'}}> +</span></h4>
            <p className='Numberspara'>Awards</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Numbers;
