// redux/filterSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  propertyType: '',
  sortByBudget: '',
  sortByLocation: ''
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setPropertyType(state, action) {
      state.propertyType = action.payload;
    },
    setSortByBudget(state, action) {
      state.sortByBudget = action.payload;
    },
    setSortByLocation(state, action) {
      state.sortByLocation = action.payload;
    }
  }
});

export const { setPropertyType, setSortByBudget, setSortByLocation } = filterSlice.actions;

export default filterSlice.reducer;
