
import React, { useState, useEffect } from "react";
import SearchPropertyData from "DataBase/PropertyData/PropertyData";
import { useNavigate } from "react-router-dom";
import { auth } from "DataBase/FireBaseConfi";
import "./Search.css";
import { toast } from "react-toastify";

function Search() {
  const searchData = SearchPropertyData();
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const [user, setUser] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(true);
      } else {
        setUser(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const filterProperties = () => {
    return searchData.filter((property) => {
      // Check if any of the property fields match the search query
      const matchesSearchQuery =
        property.propertyname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property.propertyLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property.propertyLocality.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property.propertyBy.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesSearchQuery;
    });
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleQueryClick = (property) => {
  

    navigate(`/PropertyDetails/${property.propertyname}`, {
      state: { Data: property },
    });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="row search-container">
              <div className="col-md-12">
                <input
                  id="Searchbarmain"
                  type="text"
                  placeholder="Search Your Dream Property"
                  value={searchQuery}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {searchQuery && (
        <div className="QueryDataMainDiv">
          {filterProperties().map((property) => (
            <div
              className="QueryDataMainContainer"
              key={property.id}
              onClick={() => handleQueryClick(property)}
            >
              <div className="PropertyImageContainer">
                <img src={property.coverImageUrl} alt={property.propertyname} />
              </div>
              <div className="PropertyContentContainer">
                <div className="PropertyDetailsContainer">
                  <h4>{property.propertyname}</h4>
                  <p> {property.propertyLocation}</p>
                  <p> {property.propertyLocality}</p>
              
                </div>
                <div className="PropertyPriceContainer">
                <p>By : {property.propertyBy}</p>
                  <h2 style={{ color: "white" }}>{property.propertyPrice}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Search;
