import React, { useState } from "react";
import "./CategoriesDetails.css";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";

function CategoriesDetails() {
  const location = useLocation();
  const { Data, Title } = location.state || {};
  const navigate = useNavigate();

  const [sortByBudget, setSortByBudget] = useState("0");
  const [propertyType, setPropertyType] = useState("0");
  const [sortByLocation, setSortByLocation] = useState("0");
  const [sortBySubType, setSortBySubType] = useState("0");

  const handleCategoryDataClick = (index) => {
    navigate(`/PropertyDetails/${index.propertyname}`, {
      state: { Data: index },
    });
  };

  const handleSortByBudgetChange = (event) => {
    setSortByBudget(event.target.value);
  };

  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleSortByLocationChange = (event) => {
    setSortByLocation(event.target.value);
  };

  const handleSortBySubTypeChange = (event) => {
    setSortBySubType(event.target.value);
  };

 
  const filteredData = Data.filter((property) => {
    let isMatching = true;

    // Apply filtering based on property type
    if (
      propertyType &&
      propertyType !== "0" &&
      property.propertyType !== propertyType
    ) {
      isMatching = false;
    }

    if (
      sortBySubType &&
      sortBySubType !== "0" &&
      property.subtype !== sortBySubType
    ) {
      isMatching = false;
    }

    // Apply filtering based on budget
    if (sortByBudget && sortByBudget !== "0") {
      const budgetValue = parseFloat(sortByBudget);
      let propertyPriceValue = parseFloat(property.propertyPrice);

      if (isNaN(propertyPriceValue) || propertyPriceValue > budgetValue) {
        isMatching = false;
      }
    }

    // Apply filtering based on location
    if (sortByLocation && sortByLocation !== "0") {
      const locationParts = sortByLocation.toLowerCase().split(" ");
      const propertyLocationLower = property.propertyLocation.toLowerCase();
      const locationMatched = locationParts.some((part) =>
        propertyLocationLower.includes(part)
      );
      if (!locationMatched) {
        isMatching = false;
      }
    }

    return isMatching;
  });

  const handleResetClick = () => {
    setSortByBudget("0");
    setPropertyType("0");
    setSortByLocation("0");
    setSortBySubType("0");
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h2 className="CategoryTitle">
              <b>{Title}</b>
            </h2>
            <div className="CategoriesDetailsBudgetParentDiv">
              <div className="CategoriesDetailsBudget">
                <h6>&#x20b9; Budget:</h6>
                <select
                  className="CategorySelect"
                  onChange={handleSortByBudgetChange}
                  value={sortByBudget}
                >
                  <option value="0">Sort By Budget </option>
                  <option value="1">Under 1 Cr </option>
                  <option value="4">Under 4 Cr</option>
                  <option value="6">Under 6 Cr</option>
                  <option value="8">Under 8 Cr</option>
                  <option value="10">Under 10 Cr</option>
                  <option value="12">Under 12 Cr</option>
                  <option value="14">Under 14 Cr</option>
                  <option value="16">Under 16 Cr</option>
                  <option value="20">Under 20 Cr</option>
                  <option value="50">Under 50 Cr</option>
                </select>
              </div>

              <div className="CategoriesDetailsBudget">
                <h6> Property Type:</h6>
                <select
                  className="CategorySelect"
                  onChange={handlePropertyTypeChange}
                  value={propertyType}
                >
                  <option value="0">Sort By Property Type</option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>
                </select>
              </div>

              <div className="CategoriesDetailsBudget">
                <h6> Property Location:</h6>
                <select
                  className="CategorySelect"
                  onChange={handleSortByLocationChange}
                  value={sortByLocation}
                >
                  <option value="0">Sort By Location </option>
                  <option value="Delhi">Delhi </option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Gurugram">Gurugram</option>
                  <option value="Noida">Noida</option>
                </select>
              </div>
              <div className="CategoriesDetailsBudget">
                <h6> Property Sub-Type:</h6>
                <select
                  className="CategorySelect"
                  onChange={handleSortBySubTypeChange}
                  value={sortBySubType}
                >
                  <optgroup label="Residential" className="Optgroup">
                    <option value="0">Select Sub Type</option>
                    <option value="Apartments">Apartments</option>
                    <option value="Floors">Floors</option>
                    <option value="Plots">Plots</option>
                    <option value="Villas">Villas</option>
                    <option value="Tailor-Made Apartments">
                      Tailor-Made Apartments
                    </option>
                    <option value="Independent Floors">
                      Independent Floors
                    </option>
                    <option value="Triplex Sky villa">Triplex Sky villa</option>
                    <option value="Duplex Sky villa">Duplex Sky villa</option>
                    <option value="Triplex Duplex Sky villa">
                      Triplex &amp; Duplex Sky villa
                    </option>
                    <option value="Simplex Duplex">Simplex &amp; Duplex</option>
                  </optgroup>
                  <optgroup label="Commercial" className="Optgroup">
                    <option value="0">Select Sub Type</option>
                    <option value="Retails">Retails</option>
                    <option value="Offices">Offices</option>
                    <option value="Serviced Apartments">
                      Serviced Apartments
                    </option>
                    <option value="Medical Suites">Medical Suites </option>
                    <option value="IT / ITES">IT / ITES </option>
                    <option value="Hotel Apartments">Hotel Apartments </option>
                    <option value="Studio Apartments">Studio Apartments</option>
                  </optgroup>
                </select>
              </div>

              <div className="CategoriesDetailsFilterBtn">
                <Button
                  label="Reset"
                  style={{
                    backgroundColor: "lightblue",
                    padding: "8px",
                    borderRadius: "10px",
                  }}
                  onClick={handleResetClick}
                />
              </div>
            </div>
          </div>

          <div
            className="col-md-8 CategoriesDetailsCards"
            style={{ textAlign: "left", cursor: "pointer" }}
          >
            {filteredData.map((index) => (
              <div
                className="row CategoriesDetailsCardContent"
                key={index}
                onClick={() => handleCategoryDataClick(index)}
              >
                {/* Cover Image Here */}
                <div className="col-md-4">
                  <div className="CategoriesDetailsImg">
                    <img src={index.coverImageUrl} alt="Category" />
                  </div>
                </div>
                {/* Card Content Here */}
                <div className="col-md-8">
                  <div className="CategoriesDetailsContent">
                    <div className="row">
                      <div className="col-md-9">
                        <h3>{index.propertyname}</h3>
                        <p>
                          <i className="bx bxs-map"></i>
                          {index.propertyLocality +" - "+ index.propertyLocation}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          <i className="bx bx-rupee"></i> {index.propertyPrice}
                        </p>
                      </div>
                    </div>
                    <div className="row categoryMidRow">
                      <div className="col-md-4">
                        <p>
                          {" "}
                          <b>Property Type</b>
                        </p>
                        <p>{index.propertyType}</p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <b>Sub Type</b>
                        </p>
                        <p>{index.subtype}</p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <b>Availability</b>
                        </p>
                        <p>Booking Open</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <p>
                          <b>By : </b>
                          {index.propertyBy}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoriesDetails;
