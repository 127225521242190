import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import useRecentlyAddedData from "DataBase/PropertyData/PropertyData";
import "./FeaturedCollection.css";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { auth } from "DataBase/FireBaseConfi";
import 'firebase/auth';
import { getAuth } from 'firebase/auth';
import app from "DataBase/FireBaseConfi";
import {toast as toastify } from 'react-toastify';



export default function FeaturedCollection() {
  const FocusedProject = useRecentlyAddedData();
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  
  const featuredCategoriesData = [
    {
      id: 1,
      title: "Independent Houses",
      description: "Enjoy the freedom and privacy of standalone living spaces.",
      image:
        "https://images.unsplash.com/photo-1504615755583-2916b52192a3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 2,
      title: "Ready to Move-In",
      description: "Comfortable homes available for immediate occupancy.",
      image:
        "https://plus.unsplash.com/premium_photo-1663126298656-33616be83c32?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 3,
      title: "Townships",
      description: "Modern communities redefining communal living.",
      image:
        "https://www.commercialproperty.review/wp-content/uploads/2021/03/Aparna-Serene-Park-Apartments-Kondapur-Hyderabad.jpg",
      propertyCount: 0,
    },
    {
      id: 4,
      title: "Luxury",
      description: "Premium housing tailored for those with discerning tastes.",
      image:
        "https://images.unsplash.com/photo-1613977257363-707ba9348227?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 5,
      title: "Studio",
      description:
        "Compact yet efficient living spaces for the on-the-go lifestyle.",
      image:
        "https://plus.unsplash.com/premium_photo-1679079455767-1bbb40492d6a?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 6,
      title: "Affordable Homes",
      description: "Budget-friendly housing options for cost-conscious buyers.",
      image:
        "https://images.unsplash.com/photo-1628133287836-40bd5453bed1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 7,
      title: "Builder Floor",
      description: "Independent living units at competitive prices.",
      image:
        "https://images.unsplash.com/photo-1479839672679-a46483c0e7c8?q=80&w=1910&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 8,
      title: "Apartments",
      description: "Modern living spaces with shared amenities.",
      image:
        "https://images.unsplash.com/photo-1567684014761-b65e2e59b9eb?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 9,
      title: "Condos",
      description:
        "Individual units in a shared complex, offering convenience and amenities.",
      image:
        "https://plus.unsplash.com/premium_photo-1684175656320-5c3f701c082c?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 10,
      title: "Vacation Homes",
      description: "Ideal retreats for holiday getaways and relaxation.",
      image:
        "https://plus.unsplash.com/premium_photo-1684338795288-097525d127f0?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      propertyCount: 0,
    },
    {
      id: 11,
      title: "Cooperatives (Co-ops)",
      description: "Shared ownership communities offering affordable living.",
      image: "https://jooinn.com/images/office-building-26.jpg",
      propertyCount: 0,
    },
    {
      id: 12,
      title: "Office Buildings",
      description: "Prime commercial spaces for businesses and enterprises.",
      image:
        "https://www.odell.com/wp-content/gallery/corporate-office-building-turtle-creek/1.jpg",
      propertyCount: 0,
    },
    {
      id: 13,
      title: "Retail Properties",
      description: "Commercial spaces suitable for retail businesses.",
      image:
        "https://southpace.com/wp-content/uploads/2018/10/main-image-retail-02.jpg",
      propertyCount: 0,
    },
    {
      id: 14,
      title: "Hotels and Hospitality",
      description: "Hospitality properties offering accommodation and amenities.",
      image:
        "https://www.aoarchitects.com/wp-content/uploads/Ollie_01-2880x1350_Modular-1.jpg",
      propertyCount: 0,
    },
    {
      id: 15,
      title: "Multifamily Buildings",
      description: "Residential buildings housing multiple families.",
      image:
        "https://cdnassets.hw.net/3c/d0/5914c0664bf7a7db89f35cba593d/rethinkwood-brooklyn-w1500.jpg",
      propertyCount: 0,
    },
  ];
  useEffect(() => {
    // 
    const auth = getAuth(app);

    if (auth) {
      // User is signed in.
      setUser(auth);
    } else {
      // No user is signed in.
      setUser(null);
    }

    
  }, []);

  useEffect(() => {
    const categoryCounts = {};
    const data = {};
  
    FocusedProject.forEach((property) => {
      const category = property.category;
      if (category) {
        const matchedCategory = featuredCategoriesData.find((cat) => {
          const distance = levenshteinDistance(
            cat.title.toLowerCase(),
            category.toLowerCase()
          );
          return distance < 5;
        });
  
        if (matchedCategory) {
          const matchedCategoryTitle = matchedCategory.title;
          categoryCounts[matchedCategoryTitle] =
            (categoryCounts[matchedCategoryTitle] || 0) + 1;
          if (!data[matchedCategoryTitle]) {
            data[matchedCategoryTitle] = [];
          }
          data[matchedCategoryTitle].push(property);
        }
      }
    });
  
    const updatedCategories = featuredCategoriesData.map((category) => ({
      ...category,
      propertyCount: categoryCounts[category.title] || 0,
    }));
  
    setCategories(updatedCategories);
    setCategoryData(data);
  }, [FocusedProject, featuredCategoriesData]);
  





  function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];
    let i, j;

    // Initialize matrix
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j] + 1
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }

  const handleCategoryClick = (category) => {
   

    if (category.propertyCount > 0) {
      const dataToSend = categoryData[category.title];
      navigate("/Category_Data", {
        state: { Data: dataToSend, Title: category.title },
      });
    } else {
      toast.error(`No properties available for category "${category.title}"`);
      toastify.warn(`No properties available for category "${category.title}"`);
    }
  };

  const categoryTemplate = (category) => {
    return (
      <div
        className="FeaturedCollectioncard"
        onClick={() => handleCategoryClick(category)}
      >
        <div className="ribbon">{category.propertyCount || "0"} Properties</div>
        <img src={category.image} alt={category.title} />
        <div className="FeaturedCollectioncardcontent">
          <h4>{category.title}</h4>
          <p>{category.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="FeaturedCollectioncardmaindiv ">
      <h2 className="RecentlyAddedH2">
        Featured <b>Categories</b>
      </h2>
      <Carousel
        value={categories}
        numScroll={1}
        numVisible={4}
        itemTemplate={categoryTemplate}
        responsiveOptions={[
          {
            breakpoint: "1300px",
            numVisible: 4,
            numScroll: 1,
          },
          {
            breakpoint: "1100px",
            numVisible: 3,
            numScroll: 1,
          },
          {
            breakpoint: "800px",
            numVisible: 2,
            numScroll: 1,
          },
          {
            breakpoint: "600px",
            numVisible: 1,
            numScroll: 1,
          },
        ]}
      />
    </div>
  );
}
