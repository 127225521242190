import React from 'react'
import './PhLandingPage.css'
import PhHeaderContent from '../PhHeaderContent/PhHeaderContent'
import FocusedProjects from 'Components/Focused Projects/FocusedProjects'
import RecentlyAdded from 'Components/Recently Added/RecentlyAdded'
import FeaturedCollection from 'Components/Featured Collection/FeaturedCollection'
import PhSearch from '../PhSearch/PhSearch'
import Faqs from 'Components/Faqs/Faqs'
import Testimonials from 'Components/Testimonials/Testimonials'
function PhLandingPage() {

  return (
    <div>
        
          <PhHeaderContent/>
        
          <div className="PhHeaderSearch">
               <PhSearch/>
            </div> 
        <div className="PhFocusedMainDiv" >
          <FocusedProjects/>
        </div>

        <div className="PhReacentlyAdded">
          <RecentlyAdded/>
        </div>
        <div className="PhCategory">
          <FeaturedCollection/>
        </div>
        <Testimonials/>
        <Faqs/>
       

      
    </div>
  )
}

export default PhLandingPage