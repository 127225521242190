import React, { useState, useEffect } from "react";
import "./Contact.css";
import LogoIcon from "Assests/icon.svg";
import app from "DataBase/FireBaseConfi";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";
import { toast as pctoast } from "react-toastify";

import { auth } from "DataBase/FireBaseConfi";
function Contact() {
  const db = getFirestore(app);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const currentDate = new Date().toLocaleDateString();
  const currentTime = new Date().toLocaleTimeString();
  const [user, setUser] = useState(false);
  const [userData, setUserData] = useState([null]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
     
        setUserData(user);
        setUser(true);
      } else {
        setUser(false);
        setUserData(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleContactSubmitClick = async () => {
    const emptyFields = [];
    if (!user) {
      pctoast("Please Login to continue...");
      return;
    }
    if (!name) {
      emptyFields.push("Name");
    }
    if (!email) {
      emptyFields.push("Email");
    }
    if (!phone) {
      emptyFields.push("Phone");
    }
    if (!message) {
      emptyFields.push("Message");
    }

    if (emptyFields.length > 0) {
      const emptyFieldsMessage = `Please fill in the following required fields: ${emptyFields.join(
        ", "
      )}`;
      toast.error(emptyFieldsMessage);
      return;
    }
    try {
      const userCollectionRef = collection(db, "Messages");

      const userDocRef = doc(userCollectionRef, userData.email);

      // Check if the document already exists
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userData = docSnap.data().userData || [];
        const newData = {
          Contact_Number: phone,
          DisplayPic: userData.photoURL,
          Email: email,
          Message: message,
          Name: name,
          currentDate,
          currentTime,
          ...user,
        };
        userData.push(newData);
        await setDoc(userDocRef, { userData });
        console.log("Document updated with ID: ", userDocRef.id);
      } else {
        // If document doesn't exist, create a new one with the user data array
        const userData = [
          {
            Contact_Number: phone,
            Email: email,
            DisplayPic: userData.photoURL,
            Message: message,
            Name: name,
            currentDate,
            currentTime,
            ...user,
          },
        ];
        await setDoc(userDocRef, { userData });
        console.log("Document created with ID: ", userDocRef.id);
      }
      toast.success(
        "We received you message our team we soon get in Touch with you."
      );
      handleClearFields();
      return "User data saved successfully";
    } catch (err) {
      console.error(err);
    }
  };

  const handleClearFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };


  return (
    <div className="ContactMainDiv">
      <div className="container contact-form">
        <div className="Imgcontainer">
          <div className="contact-image">
            <img src={LogoIcon} alt="contact" className="LogoImg" />
          </div>
        </div>

        <form>
          <h3>Drop Us a Message</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  name="txtName"
                  className="form-control"
                  placeholder="Your Name *"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="txtEmail"
                  className="form-control"
                  placeholder="Your Email *"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group flex ">
                <input
                  type="number"
                  name="txtPhone"
                  className="form-control"
                  placeholder="Your Phone Number *"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
             
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <textarea
                  name="txtMsg"
                  className="form-control"
                  placeholder="Your Message *"
                  style={{ width: "100%", height: "150px" }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </form>
        <div className="ContactInfos">
          <div>
            <a href="tel:9205940363">
              <i
                className="bx bxs-phone-call"
                style={{
                  color: "rgb(146, 179, 206)",
                  borderRadius: "5px",
                  fontSize: "20px",
                }}
              >
                {" "}
              </i>
              Contact us
            </a>
          </div>

          <div>
            <a href="mailto:official.ncr.realestate@gmail.com">
              <i
                className="bx bxl-gmail"
                style={{
                  backgroundColor: "rgb(236, 236, 224)",
                  color: "red",
                  borderRadius: "5px",
                  fontSize: "20px",
                }}
              ></i>
              Mail us
            </a>
          </div>

          <div>
            <a href="https://chat.whatsapp.com/KEXXV3pF7ql5cM0toinpSZ">
              <i
                className="bx bxl-whatsapp"
                style={{
                  backgroundColor: "rgb(78, 228, 94)",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "20px",
                }}
              ></i>
              Join our community
            </a>
          </div>
        </div>
        <br />
        <div className="SubmitBtn">
          <button
            onClick={() => handleContactSubmitClick()}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
        <br />
      </div>
    </div>
  );
}

export default Contact;
