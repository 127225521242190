import React, { useState, useEffect } from "react";
import "./RecentlyAdded.css";
import useRecentlyAddedData from "DataBase/PropertyData/PropertyData";
import { useNavigate } from "react-router-dom";

import { getAuth } from "firebase/auth";
import app from "DataBase/FireBaseConfi";
import { Skeleton } from 'primereact/skeleton';

import { MdFilterListAlt } from "react-icons/md";
function RecentlyAdded() {
  const navigate = useNavigate();
  const recentlyAddedData = useRecentlyAddedData();
 
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
    const [sortByBudget, setSortByBudget] = useState("0");
  const [propertyType, setPropertyType] = useState("0");
  const [sortByLocation, setSortByLocation] = useState("0");
  const [sortBySubType, setSortBySubType] = useState("0");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function handleRecentlyAddedClick(property) {
    navigate(`/PropertyDetails/${property.propertyname}`, {
      state: { Data: property },
    });
  }

  const handleSortByBudgetChange = (event) => {
    setSortByBudget(event.target.value);
  };

  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleSortByLocationChange = (event) => {
    setSortByLocation(event.target.value);
  };

  const handleSortBySubTypeChange = (event) => {
    setSortBySubType(event.target.value);
  };

  const filteredRecentlyAddedData = recentlyAddedData.filter((property) => {
    let isMatching = true;

    // Apply filtering based on property type
    if (
      propertyType &&
      propertyType !== "0" &&
      property.propertyType !== propertyType
    ) {
      isMatching = false;
    }

    // Apply filtering based on budget
    if (sortByBudget && sortByBudget !== "0") {
      const budgetValue = parseFloat(sortByBudget);
      let propertyPriceValue = parseFloat(property.propertyPrice);

      if (isNaN(propertyPriceValue) || propertyPriceValue > budgetValue) {
        isMatching = false;
      }
    }

    // Apply filtering based on location
    if (sortByLocation && sortByLocation !== "0") {
      const locationParts = sortByLocation.toLowerCase().split(" ");
      const propertyLocationLower = property.propertyLocation.toLowerCase();
      const locationMatched = locationParts.some((part) =>
        propertyLocationLower.includes(part)
      );
      if (!locationMatched) {
        isMatching = false;
      }
    }
    if (
      sortBySubType &&
      sortBySubType !== "0" &&
      property.subtype !== sortBySubType
    ) {
      isMatching = false;
    }

    return isMatching;
  });

  const propertyTemplate = (property) => {
    return (
      <div
        className="RecentlyAddedParentDiv"
        onClick={() => handleRecentlyAddedClick(property)}
      >
        <div className="img">
          <img src={property.coverImageUrl} alt="Property" draggable="false" />
        </div>
        <div className=" RecentlyAddedContent ">
          <div >
            <h6>{property.propertyname}</h6>
            <h6>{property.subtype}</h6>
            <label style={{ color: "teal" }}>
              {property.propertyLocality + " - " + property.propertyLocation}
            </label>
          </div>
          <div >
            <h5 style={{ textAlign: "right" }}>&#8377; {property.propertyPrice}</h5>
          </div>
        </div>
      </div>
    );
  };
  const handleResetClick = () => {
    setSortByBudget("0");
    setPropertyType("0");
    setSortByLocation("0");
    setSortBySubType("0");
  };
  const toggleFilter = () => {
    setIsActive(!isActive);
    setIsHidden(!isHidden);
  };
  return (
    <div className="p-col RecentlyAddedMainDiv ">
      <h2 className="RecentlyAddedH2">
      Fresh <b>Listings</b>
      </h2>
<div className="RecentlyAddedFilterIcon">
   <MdFilterListAlt
          onClick={toggleFilter}
          
          />
</div>
     
        
             <div className={`FLshowFilterParentDiv ${isActive ? "active" : ""} ${isHidden ? "hidden" : ""}`}>
              <div className="FilterStickyContent">
                    <div className="PhCategoriesDetailsBudget">
              <h6>&#x20b9; Budget:</h6>
              
              <select
              name="FilterStickyContentDropdown"
                id="FLSelect"
                onChange={handleSortByBudgetChange}
                value={sortByBudget}
              >
                <option value="0">Sort By Budget </option>
                <option value="1">Under 1 Cr </option>
                <option value="4">Under 4 Cr</option>
                <option value="6">Under 6 Cr</option>
                <option value="8">Under 8 Cr</option>
                <option value="10">Under 10 Cr</option>
                <option value="12">Under 12 Cr</option>
                <option value="14">Under 14 Cr</option>
                <option value="16">Under 16 Cr</option>
                <option value="20">Under 20 Cr</option>
                <option value="50">Under 50 Cr</option>
              </select>
            </div>

            <div className="PhCategoriesDetailsBudget">
              <h6> Property Type:</h6>
              <select
                  name="FilterStickyContentDropdown"
                  id="FLSelect"
                onChange={handlePropertyTypeChange}
                value={propertyType}
              >
                <option value="0">Sort By Property Type</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
              </select>
            </div>

            <div className="PhCategoriesDetailsBudget">
              <h6> Property Location:</h6>
              <select
                  name="FilterStickyContentDropdown"
                  id="FLSelect"
                onChange={handleSortByLocationChange}
                value={sortByLocation}
              >
                <option value="0">Sort By Location </option>
                <option value="Delhi">Delhi </option>
                <option value="Mumbai">Mumbai</option>
                <option value="Gurugram">Gurugram</option>
                <option value="Noida">Noida</option>
              </select>
            </div>
            <div className="PhCategoriesDetailsBudget">
              <h6> Property Sub-Type:</h6>
              <select
                 name="FilterStickyContentDropdown"
                 id="FLSelect"
                onChange={handleSortBySubTypeChange}
                value={sortBySubType}
              >
                <optgroup label="Residential" className="Optgroup">
                  <option value="0">Select Sub Type</option>
                  <option value="Apartments">Apartments</option>
                  <option value="Floors">Floors</option>
                  <option value="Plots">Plots</option>
                  <option value="Villas">Villas</option>
                  <option value="Tailor-Made Apartments">
                    Tailor-Made Apartments
                  </option>
                  <option value="Independent Floors">Independent Floors</option>
                  <option value="Triplex Sky villa">Triplex Sky villa</option>
                  <option value="Duplex Sky villa">Duplex Sky villa</option>
                  <option value="Triplex Duplex Sky villa">
                    Triplex &amp; Duplex Sky villa
                  </option>
                  <option value="Simplex Duplex">Simplex &amp; Duplex</option>
                </optgroup>
                <optgroup label="Commercial" className="Optgroup">
                  <option value="0">Select Sub Type</option>
                  <option value="Retails">Retails</option>
                  <option value="Offices">Offices</option>
                  <option value="Serviced Apartments">
                    Serviced Apartments
                  </option>
                  <option value="Medical Suites">Medical Suites </option>
                  <option value="IT / ITES">IT / ITES </option>
                  <option value="Hotel Apartments">Hotel Apartments </option>
                  <option value="Studio Apartments">Studio Apartments</option>
                </optgroup>
              </select>
            </div>

            <button  onClick={handleResetClick}>
             
              <i  style={{
      fontSize:'20px',
      margin:'1px'
    }} class='bx bx-reset'></i>
            </button>
              </div>
        
          </div>
       
         
        
      
      <div>
        {filteredRecentlyAddedData.length > 0 ? (
          <div className="RecentlyAddedList">
            {filteredRecentlyAddedData.map((property) => (
              <div key={property.id} className="RecentlyAddedListItem">
                {propertyTemplate(property)}
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full " style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="flex align-items-end">
              <Skeleton size="20rem"></Skeleton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecentlyAdded;
