// import React from "react";
// import "./PriceList.css";

// function PriceList({ priceDetails }) {
//   return (
//     <div className="PriceListMainDiv">
//       <div className="container ">
//         <div className="text-effect ">
//           <span>PRICE LIST</span>
//         </div>
//         <div className="row ">
//           <div className="col-md-12 pricelistRow ">
//             <div
//               className="panel-group"
//               id="accordion"
//               role="tablist"
//               aria-multiselectable="true"
//             >
//               {/* Render panels dynamically based on the priceDetails */}
//               {priceDetails.map((panel, index) => (
//                 <div className="panel panel-default" key={index}>
//                   <div
//                     className="panel-heading"
//                     role="tab"
//                     id={`heading${index}`}
//                   >
//                     <h4 className="panel-title">
//                       <a
//                         className="collapsed"
//                         role="button"
//                         data-toggle="collapse"
//                         data-parent="#accordion"
//                         href={`#collapse${index}`}
//                         aria-expanded="false"
//                         aria-controls={`collapse${index}`}
//                       >
//                         {panel.priceHeader}
//                       </a>
//                     </h4>
//                   </div>
//                   <div
//                     id={`collapse${index}`}
//                     className="panel-collapse collapse"
//                     role="tabpanel"
//                     aria-labelledby={`heading${index}`}
//                   >
//                     <div className="panel-body">

//                         <div >
//                           <h4>Unit Type</h4>
//                           <hr />
//                           <ul>
//                             {/* Render unit type items */}
//                             {panel.unitTypes.map((type, idx) => (
//                               <li key={idx}>{type}</li>
//                             ))}
//                           </ul>
//                         </div>
//                         <div >
//                           <h4>Unit Size</h4>
//                           <hr />
//                           <h6>{panel.unitSize}</h6>
//                         </div>
//                         <div >
//                           <h4>Unit Price</h4>
//                           <hr />
//                           <h6>{panel.unitPrice}</h6>
//                         </div>
//                       </div>
//                     </div>

//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default PriceList;

import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./PriceList.css";

export default function PriceList({ priceDetails }) {
  return (
    <div className="PriceListMainDiv">
      <div className="text-effect ">
        <span>PRICE LIST</span>
      </div>
      <div className="card p-2">
        <Accordion activeIndex={0}>
          {priceDetails.map((panel, index) => (
            <AccordionTab header={panel.priceHeader} key={index}>
              <div className="panel-body">
                <div>
                  <label className="AccordionHeader">Unit Type</label>
                
                  <ul>
                    {/* Render unit type items */}
                    {panel.unitTypes.map((type, idx) => (
                      <li key={idx}>{type}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <label className="AccordionHeader">Unit Size</label>
                
                  <h6>{panel.unitSize}</h6>
                </div>
                <div>
                  <label className="AccordionHeader">Unit Price</label>
                
                  <h6>{panel.unitPrice}</h6>
                </div>
              </div>
            </AccordionTab>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
