import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import './Faqs.css'
export default function Faqs() {
  return (
    <div className=" p-2">
      <Accordion activeIndex={0}>
        <AccordionTab
          header="1. What sets NCR-RealEstate apart from other real estate projects
              in the region?"
        >
          <p className="m-0">
           <b>NCR-RealEstate</b>  distinguishes itself through a blend of modern
            design, sustainable practices, and a commitment to creating vibrant
            communities. Our focus on quality, innovation, and customer
            satisfaction positions us as a leader in the real estate market.
          </p>
        </AccordionTab>
       
        <AccordionTab
          header="2. How can I explore available properties and initiate the
              purchase process with NCR-RealEstate?"
        >
          <p className="m-0">
            To explore our available properties and start the purchasing
            process, simply reach out to our sales team through our website or
            visit our sales office. We are dedicated to guiding you through the
            selection and purchase of the perfect property tailored to your
            needs.
          </p>
        </AccordionTab>
        <AccordionTab
          header="3. What sustainability features are integrated into
              NCR-RealEstate, and how does it contribute to eco-friendly living?"
        >
          <p className="m-0">
            NCR-RealEstate is committed to sustainability, incorporating
            eco-friendly initiatives such as energy-efficient designs, green
            spaces, and environmentally conscious construction materials. Our
            aim is to create not just homes but a community that prioritizes and
            nurtures a greener, healthier lifestyle.
          </p>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
