import React from "react";
import "./About.css";
function About() {
  return (
    <div>
      <header className="AboutHeader">
        <h1>NCR Real Estate</h1>
        <p>Your Gateway to Real Estate in the National Capital Region</p>
      </header>

      <section id="about">
        <h2>About Us</h2>
        <p>
          NCR Real Estate is a revolutionary platform combining real estate and
          technology to provide an exceptional experience for property buyers
          and investors.
        </p>
      </section>

      <section id="services">
        <h2>Our Services</h2>
        <ul>
          <li>Real Estate Investment Advising</li>
          <li>Home Loans</li>
          {/* <!-- Add more services as needed --> */}
        </ul>
      </section>

      <section id="features">
        <h2>Key Features</h2>
        <ul>
          <li>Easy Navigation</li>
          <li>Browse by Budget, Location, and Builder</li>
          <li>Project of The Month and Hot Projects Gallery</li>
          {/* <!-- Add more features as needed --> */}
        </ul>
      </section>

      <section id="vision">
        <h2>Vision</h2>
        <p>
          Enabling the availability of the best real estate solutions, our
          vision is to be an honest partner for real estate investment.
        </p>

       
      </section>

      <section id="mission">
      <h2>Mission</h2>
        <p>
          Our mission is to open the way to capital generation in the real
          estate field, presenting an exclusive experience for buying real
          estate.
        </p>
      </section>

    
    </div>
  );
}

export default About;
