// In your redux folder, create a slice file for user

// userSlice.js

import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  user: null,
};

// Define slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Export actions
export const { setUser } = userSlice.actions;

// Export reducer
export default userSlice.reducer;
