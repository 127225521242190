
import { useEffect, useState } from "react";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import app from "DataBase/FireBaseConfi";

// Custom hook for fetching data from Firebase
function useFetchFirebaseData() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firestore = getFirestore(app);
        const projectRef = collection(firestore, "Property");
        const unsubscribe = onSnapshot(projectRef, (querySnapshot) => {
          const updatedProperties = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
          setData(updatedProperties);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {};
  }, []);

  return data;
}

export default useFetchFirebaseData;