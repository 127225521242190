import React from "react";
import Logo from "Components/Logo/Logo";
import LoginDropdown from "Components/Login/LoginDropdown";
import "./Header.css";
import PhNav from "../PhNav";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';

function Header() {
  return (
    <div className="HeaderFixedParnt">
      <div className="HeaderMainDiv">
        <div className="HeaderLogoDiv">
          <Link to="/home">
            <Logo />
          </Link>
        </div>
        <div className="HeaderLoginDiv">
          <LoginDropdown />
        </div>
        <div className="PhnavBar">
          <PhNav />
        </div>
      </div>
      {/* <Toaster /> */}
      
    </div>
  );
}

export default Header;
