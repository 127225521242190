import React from "react";
import "./LandingPage.css";
import Search from "Components/Search/Search";
import Numbers from "Components/Numbers/Numbers";
import FocusedProjects from "Components/Focused Projects/FocusedProjects";
import RecentlyAdded from "Components/Recently Added/RecentlyAdded";
import Animation from "Components/Animation/Animation";
import Testimonials from "Components/Testimonials/Testimonials";
import Faqs from "Components/Faqs/Faqs";
import Footer from "Components/Footer/Footer";
import Filter from "Components/Filter/Filter";
import FeaturedCollection from "Components/Featured Collection/FeaturedCollection";

function LandingPage() {
  return (
    <div>
      <div className="LandingPageMainDiv">
        <div className="blob"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 HeaderContentMainDiv">
              <div className="Title">
                <h1 className="home-title" id="HomeTitle">
                  <span>Discover</span>
                  <span>Most Suitable</span>
                  <span>Property</span>
                </h1>
              </div>
              <br />
              <div className="Search">
                <Search />
              </div>
              <br /> <br />
              <div className="Numbers">
                <Numbers />
              </div>
            </div>
            <div className="col-md-6 AnimationMainDiv">
              <Animation />
            </div>
          </div>
        </div>
      </div>

     
      {/* Focused Projects  */}
      <div className="FoucusedProjectsMainDiv">
        <br />
        <br />
        <FocusedProjects />
      </div>
  
      <div className="RecentlyAddedProjects">
        <RecentlyAdded />
      </div>

    
   
      <div className="FeaturedCollection">
        <FeaturedCollection/>
      </div>
   
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <Testimonials />
          </div>
          <div className="col-md-6">
            <Faqs />
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default LandingPage;
