import React, { useState, useEffect } from "react";
import SearchPropertyData from "DataBase/PropertyData/PropertyData";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import './PhSearch.css'
import app from "DataBase/FireBaseConfi";
import { getAuth } from "firebase/auth";

function PhSearch() {
  const searchData = SearchPropertyData();
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const filterProperties = () => {
    return searchData.filter((property) => {
      // Check if any of the property fields match the search query
      const matchesSearchQuery =
        property.propertyname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property.propertyLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property.propertyLocality.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property.propertyBy.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesSearchQuery;
    });
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleQueryClick = (property) => {
  
    navigate(`/PropertyDetails/${property.propertyname}`, {
      state: { Data: property },
    });
  };
  return (
    <div>
        <div className="PhSearchInput">
            <input type="text" placeholder='Search your Dream Property'  value={searchQuery}
                  onChange={handleInputChange} />
        </div>
        {searchQuery && (
        <div className="PhQueryDataMainDiv">
          {filterProperties().map((property) => (
            <div
              className="PhQueryDataMainContainer"
              key={property.id}
              onClick={() => handleQueryClick(property)}
            >
              <div className="PhPropertyImageContainer">
                <img src={property.coverImageUrl} alt={property.propertyname} />
              </div>
              <div className="PhPropertyContentContainer">
                <div className="PhPropertyDetailsContainer">
                  <h6>{property.propertyname}</h6>
                  <p> {property.propertyLocation}</p>
                  <p> {property.propertyLocality}</p>
              
                </div>
                <div className="PhPropertyPriceContainer">
                <p>By <br /> {property.propertyBy}</p>
                  <h2 style={{ color: "white" }}>{property.propertyPrice}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default PhSearch