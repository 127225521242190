
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getFirestore, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import app from "DataBase/FireBaseConfi";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LoginDropdown.css";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

function LoginDropdown() {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const currentDate = new Date().toLocaleDateString();
  const currentTime = new Date().toLocaleTimeString();
  const [userData, setUserData] = useState({
    phoneNumber: "",
    interest: "",
  });
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const Userdata = getAuth().currentUser;

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setShowModal(false); // Hide modal when user is logged in
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function menuToggle() {
    if (user === null) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        setShowModal(true);
      }, 500);
    } else {
      const toggleMenu = document.querySelector(".menu");
      toggleMenu.classList.toggle("active");
    }
  }

  const handleGoogleLogin = async () => {
    if (userData.phoneNumber.length !== 10) {
      setPhoneNumberError("Phone number must be 10 digits long");
      return;
    }
    setShowModal(false);
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
  
    try {
      const response = await signInWithPopup(auth, provider);
      const { user } = response;
      toast.success("Logged in successfully! 😍");
      setUser(user);
  
      const Userdata = getAuth().currentUser;
      const firestore = getFirestore(app);
      const ProfileRef = doc(firestore, "Profile", Userdata.email);
      const ProfileSnapshot = await getDoc(ProfileRef);
  
      if (ProfileSnapshot && ProfileSnapshot.exists()) {
        // Document exists, update it
        updateDoc(ProfileRef, {
          DisplayPic: Userdata.photoURL,
          Email: Userdata.email,
          DisplayName: Userdata.displayName,
          loginDate: currentDate,
          loginTime: currentTime,
          PhoneNumber: userData.phoneNumber,
          Interests: userData.interest,
        });
      } else {
        // Document doesn't exist, create it
        setDoc(ProfileRef, {
          DisplayPic: Userdata.photoURL,
          Email: Userdata.email,
          DisplayName: Userdata.displayName,
          loginDate: currentDate,
          loginTime: currentTime,
          PhoneNumber: userData.phoneNumber,
          Interests: userData.interest,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleLogout = () => {
    const auth = getAuth(app);
    auth.signOut().then(() => {
      setUser(null);
      const toggleMenu = document.querySelector(".menu");
      toggleMenu.classList.remove("active");
      navigate("/home");
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  const handleInboxClick = () => {
    toast("It's still in development phase, please wait for updates");
    const toggleMenu = document.querySelector(".menu");
    toggleMenu.classList.remove("active");
  };

  const handlePhoneNumberChange = (event) => {
    const phoneNumber = event.target.value;
    setUserData({ ...userData, phoneNumber });
    setPhoneNumberError("");
  };

  const handleInterestChange = (event) => {
    const interest = event.target.value;
    setUserData({ ...userData, interest });
  };

  return (
    <div className="LoginDropdwon">
      <div className="action">
        <div className="profile" onClick={menuToggle}>
          <img
            src={
              user?.photoURL ||
              "https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg"
            }
            alt="Placeholder"
          />
        </div>
        <div className="menu">
          <h3 style={{ textTransform: "capitalize" }}>
            {user?.displayName || "Ncr-RealEstate"}
            <br />
            <span style={{ fontSize: "11px", textTransform: "lowercase" }}>
              {user?.email || "Please Login"}
            </span>
          </h3>
          <ul>
            <li onClick={() => handleInboxClick()}>
              <i className="bx bxs-message-dots"></i>
              <a >Inbox</a>
            </li>
            <li className="savedProperty" onClick={() => handleInboxClick()}>
              <i className="bx bxs-heart "></i>
              <a >Saved Property</a>
            </li>
            <li onClick={handleLogout}>
              <i className="bx bx-log-out"></i>
              <a>Logout</a>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Additional Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group ">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
               placeholder="Phone Number without country code"
                value={userData.phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              {phoneNumberError && (
                <div className="text-danger">{phoneNumberError}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="interest">Looking For:</label>
              <input
                type="text"
                className="form-control"
                id="interest"
                placeholder="Residential or Commercial property"
                value={userData.interest}
                onChange={handleInterestChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleGoogleLogin}>
            Login
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LoginDropdown;
